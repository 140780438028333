import { useToast } from "../context/toastContext";
import { myApi } from "../helpers/api";
import { useHttp } from "../hooks/http.hook";

const useFileService = () => {
  const { loadingStatus, request, error, clearError, setProcess, token } =
    useHttp();
  const { handleToast } = useToast();

  const fileRoutes = `${myApi}/files`;

  const uploadFile = async (data, setFile) => {
    const formData = new FormData();
    formData.append("file", data.file[0]);
    formData.append("action", data.action);
    if (data.action === "resize") {
      formData.append("width", data.width);
      formData.append("height", data.height);
    } else if (data.action === "convert") {
      formData.append("format", data.format);
    } else if (data.action === "optimize") {
      formData.append("quality", data.quality);
    }

    try {
      const res = await request(
        `${myApi}/files/upload-file`,
        "POST",
        formData,
        {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        }
      );
      handleToast("success", "File uploaded successfully");
      setFile(res.convertedFile);
    } catch (e) {
      handleToast("error", "File upload failed");
    }
  };

  const downloadFile = async (fileId) => {
    try {
      const res = await request(
        `${fileRoutes}/download/${fileId}`,
        "GET",
        null,
        {
          "Content-Type": "application/octet-stream", // Set the content type to octet-stream
        }
      );
      return res; // Return the file data
    } catch (e) {
      throw e;
    }
  };

  const getFiles = async (setFiles, setTotalCards, page = 1, limit = 10) => {
    try {
      const res = await request(
        `${fileRoutes}/user?page=${page}&limit=${limit}`,
        "GET"
      );
      setTotalCards(res.totalCount);
      setFiles(res.files);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteFile = async (id, setFiles) => {
    try {
      const res = await request(
        `${fileRoutes}/delete-file/${id}`,
        "DELETE",
        {}
      );
      setFiles((oldFiles) => {
        return oldFiles.filter((file) => file.id !== id);
      });
    } catch (e) {}
  };

  const deleteFiles = async (id) => {
    try {
      const res = await request(`${fileRoutes}/${id}`, "DELETE");
    } catch (e) {}
  };

  return {
    loadingStatus,
    error,
    downloadFile,
    uploadFile,
    setProcess,
    deleteFiles,
    deleteFile,
    getFiles,
    clearError,
  };
};

export default useFileService;
