import { useState } from "react";
import axios from "axios";

export const useHttp = () => {
  const [loadingStatus, setLoadingStatus] = useState("pending");
  const token = localStorage.getItem("token");

  const request = async (
    url,
    method = "GET",
    data = null,
    headers = {
      "Content-Type": "application/json",
      Authorization: token,
    },
    credentials
  ) => {
    setLoadingStatus("loading");
    try {
      const response = await axios({
        method,
        url,
        data,
        headers,
        withCredentials: credentials || false,
      });

      if (!response.status.toString().startsWith(2)) {
        throw new Error(response.message || "Request failed");
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    token,
    loadingStatus,
    request,
    setLoadingStatus,
  };
};
