import React from "react";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../assets/logo.png";
import { Col, Image } from "react-bootstrap";
import "./style.scss";
import { useAuth } from "../../context/userContext";
import useAuthService from "../../service/authService";

function Header() {
  const { isLoggedIn } = useAuth();
  const { logout } = useAuthService();

  return (
    <Navbar expand="lg" bg="primary" data-bs-theme="dark" className="mb-5">
      <Container style={{
        gap:"10px"
      }}>
        <Navbar.Brand href="/home">
          <Col>
            <Image
              src={Logo}
              style={{
                border: "1px solid white",
                boxShadow: "black",
                maxHeight: "30px",
                maxWidth: "30px",
                marginRight: "10px",
              }}
              roundedCircle
            />
            <span
              style={{
                fontWeight: "bold",
                fontFamily: "sans-serif",
              }}
            >
              Fast Converter
            </span>
          </Col>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="my-lg-0 mr-5"
            style={{ maxHeight: "100px", fontWeight: "bold" }}
            navbarScroll
          >
            <NavLink
              style={{
                color: "#fff",
                textDecoration: "none",
                marginLeft: "20px",
              }}
              to="/converter"
            >
              Converter
            </NavLink>
            <NavLink
              style={{
                color: "#fff",
                textDecoration: "none",
                marginLeft: "20px",
              }}
              activeClassName="active"
              to="/profile"
            >
              Profile
            </NavLink>
            <NavLink
              style={{
                color: "#fff",
                textDecoration: "none",
                marginLeft: "20px",
              }}
              activeClassName="active"
              to="/history"
            >
              History
            </NavLink>
            <NavLink
              style={{
                color: "#fff",
                textDecoration: "none",
                marginLeft: "20px",
              }}
              activeClassName="active"
              to="/formats"
            >
              Formats
            </NavLink>
          </Nav>
        </Navbar.Collapse>
        <Nav className="ml-auto" style={{
          flexDirection:"row",
        }} >
          {isLoggedIn ? (
            <NavLink
              style={{
                color: "#fff",
                backgroundColor: "#f94646",
                padding: "2px 5px",
                borderRadius: "5px",
                fontWeight: "bold",
                textDecoration: "none",
                marginRight: "10px",
              }}
              activeClassName="active"
              onClick={logout}
            >
              Logout
            </NavLink>
          ) : (
            <>
              <NavLink
                style={{
                  color: "#fff",
                  textDecoration: "none",
                  marginRight: "10px",
                }}
                activeClassName="active"
                to="/login"
              >
                Login
              </NavLink>
              <span style={{ color: "#fff", marginRight: "10px" }}>/</span>
              <NavLink
                style={{
                  color: "#fff",
                  textDecoration: "none",
                  marginRight: "10px",
                }}
                activeClassName="active"
                to="/register"
              >
                Register
              </NavLink>
            </>
          )}
        </Nav>
      </Container>
    </Navbar>
  );
}

export default Header;
