import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import InfoCard from "../../components/info-card";
import Pagination from "react-bootstrap/Pagination";
import { Container, Row, Col } from "react-bootstrap";
import { myApi } from "../../helpers/api";
import useFileService from "../../service/fileService";

const HistoryPage = () => {
  const [cardsPerPage] = useState(9);
  const { getFiles } = useFileService();
  const [currentPage, setCurrentPage] = useState(1);
  const [cards, setCards] = useState([]);
  const [totalCards, setTotalCards] = useState(1);

  useEffect(() => {
    getFiles(setCards, setTotalCards, currentPage, cardsPerPage);
  }, [currentPage]);


 
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };


  return (
    <div>
      <Container>
        <Row className="justify-content-center">
          <Col>
            <h2 className="text-center mt-5" style={{textAlign:"center",
          color:"#1C90ED"}}>Conversion History</h2>
           <p className="text-center text-muted">History automatically clears every Friday</p>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5 mb-5 g-5">
          {cards.map((file, i) => {
            return (
              <Col xs={12} md={4} key={i}>
                <InfoCard
                  file={file}
                  setCards={setCards}
                />
              </Col>
            );
          })}
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Pagination className="justify-content-center text-center">
              {[...Array(Math.ceil(totalCards / cardsPerPage)).keys()].map(
                (page) => (
                  <Pagination.Item
                    key={page + 1}
                    active={page + 1 === currentPage}
                    onClick={() => handlePageClick(page + 1)}
                  >
                    {page + 1}
                  </Pagination.Item>
                )
              )}
            </Pagination>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HistoryPage;
