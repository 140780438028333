import React from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import useAuthService from '../../../../service/authService';

const SettingsPage = () => {
  const { register, handleSubmit,getValues, formState: { errors } } = useForm();
  const {resetPassword, deleteAccount} = useAuthService()
 

  return (
    <div className="d-flex justify-content-center" style={{
      maxWidth: "300px",
      margin: "0 auto",
      marginTop: "10px"
    }}>
      <Container className="text-center">
        <h2 style={{textAlign:"center",
          color:"#1C90ED"}}>Reset Password</h2>
        <Form className='mt-5' onSubmit={handleSubmit(resetPassword)}>
          <Form.Group controlId="formOldPassword">
            <Form.Label>Old Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter old password"
              {...register("oldPassword", { required: "Old Password is required" })}
            />
            {errors.oldPassword && <p style={{color:"red"}}>{errors.oldPassword.message}</p>}
          </Form.Group>

          <Form.Group controlId="formNewPassword">
            <Form.Label className="mt-3">New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter new password"
              {...register("newPassword", { required: "New Password is required" })}
            />
            {errors.newPassword && <p style={{color:"red"}}>{errors.newPassword.message}</p>}
          </Form.Group>

          <Form.Group controlId="formConfirmPassword">
            <Form.Label className="mt-3">Confirm New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm new password"
              {...register("confirmPassword", {
                required: "Confirm New Password is required",
                validate: (value) =>
                  value === getValues("newPassword") || "Passwords do not match"
              })}
            />
            {errors.confirmPassword && <p style={{color:"red"}}>{errors.confirmPassword.message}</p>}
          </Form.Group>

          <div className="text-center mt-5">
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </div>
          <div className="text-center mt-3">
            <Button variant="danger" type="button" onClick={deleteAccount}>
              Delete Account
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default SettingsPage;
