import React from "react";
import { useForm } from "react-hook-form";
import { Form, Button, Card } from "react-bootstrap";
import useAuthService from "../../../service/authService";

const RegisterForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const {registerNewUser} = useAuthService()
  const password = React.useRef({});
  password.current = watch("password", "");


  return (
    <div style={{ minHeight: "100vh", paddingTop: "100px" }}>
      <h2
        style={{
          textAlign: "center",
        }}
      >
        Fast Registration
      </h2>
      <Card
        style={{
          maxWidth: "400px",
          margin: "auto",
          marginTop: "50px",
          padding: "20px",
          border: "1px solid #ccc",
        }}
      >
        <Form onSubmit={handleSubmit(registerNewUser)}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              {...register("email", { required: true })}
            />
            {errors.email && <p className="text-danger">Email is required</p>}
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              {...register("password", { required: true })}
            />
            {errors.password && (
              <p className="text-danger">Password is required</p>
            )}
          </Form.Group>

          <Form.Group controlId="formBasicConfirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm Password"
              {...register("confirmPassword", {
                validate: (value) =>
                  value === password.current || "The passwords do not match",
              })}
            />
            {errors.confirmPassword && (
              <p className="text-danger">{errors.confirmPassword.message}</p>
            )}
          </Form.Group>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button variant="primary" type="submit">
              Register
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default RegisterForm;
