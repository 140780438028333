import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./context/userContext";
import { ToastProvider } from "./context/toastContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter> 
   <AuthProvider>
      <ToastProvider>
          <App />
      </ToastProvider>
    </AuthProvider>
</BrowserRouter>
);
