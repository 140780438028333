import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import SettingsPage from "./profile-comp/user-settings";
import UserProfileInfo from "./profile-comp/user-info";

const UserProfile = ({ user }) => {
  return (
    <Container>
          <SettingsPage />
    </Container>
  );
};

export default UserProfile;
