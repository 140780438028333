// ConvertPage.js

import React from 'react';
import Header from '../../components/header';
import { Container } from 'react-bootstrap';
import FormatInfo from '../../components/files-format';

const About = () => {
  return (
    <div>
      <Container>
        <h2 style={{
          textAlign:"center",
          color:"#1C90ED"
        }}>File Formats</h2>
        <FormatInfo/> 
      </Container>
    </div>
  );
};

export default About;
