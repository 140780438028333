import { myApi } from "../helpers/api";
import { useHttp } from "../hooks/http.hook";
import { useAuth } from "../context/userContext";
import { useToast } from "../context/toastContext";
import { useNavigate } from 'react-router-dom';


const useAuthService = () => {
  const {
    loadingStatus,
    request,
    error,
    clearError,
    setProcess,
  } = useHttp();
  const {
    handleToast,
  } = useToast();

  const { setIsLoggedIn } = useAuth();

  const navigate = useNavigate(); 


  const authRoute = `${myApi}/auth`;

  const login = async (data) => {
    try {
      const res = await request(
        `${authRoute}/login`,
        "POST",
        JSON.stringify(data)
      );
      setIsLoggedIn(true);
      localStorage.setItem("token", res.token);

      navigate("/converter");
      
    } catch (e) {
      console.log(e);
    }
  };

  const logout = async () => {
    try {
      const res = await request(
        `${authRoute}/logout`,
        "POST",
        {}
      );
      setIsLoggedIn(false);
      localStorage.clear();
    } catch (e) {
    }
  };

  const registerNewUser = async (data) => {
    try {
      const res = await request(
        `${authRoute}/register`,
        "POST",
        JSON.stringify(data)
      );
      handleToast("success", res.message)
      localStorage.setItem('token', res.token)
    } catch (e) {
      handleToast("error","Invalid input data")
    }
  };

  const resetPassword = async (data) => {
    try {
      const res = await request(
        `${authRoute}/reset-password`,
        "POST",
        JSON.stringify(data)
      );
      handleToast("success", res.message)
    } catch (e) {
      handleToast("error","Try later")
    }
  };

  const deleteAccount = async () => {
    try {
      const res = await request(
        `${authRoute}/delete-account`,
        "DELETE",
        {}
      );
      setIsLoggedIn(false);
      localStorage.clear();
    } catch (e) {

    }
  };

  const forgotPassword = async (email) =>{
    try {
      const res = await request(
        `${authRoute}/forgot-password`,
        "POST",
        JSON.stringify({email})
      );
      handleToast("success", res.message)
    } catch (e) {
      handleToast("error","Try later")
    }
  }

  return {
    loadingStatus,
    error,
    login,
    logout,
    resetPassword,
    setProcess,
    registerNewUser,
    deleteAccount,
    forgotPassword,
    clearError,
  };
};

export default useAuthService;
