// ConvertPage.js

import React from 'react';
import { Container } from 'react-bootstrap';
import UploadForm from '../../components/forms/file-upload';

const ConvertPage = () => {
  return (
    <div>
      <Container>
        <h2 style={{
         textAlign:"center",
          color:"#1C90ED"
        }}>Change Your File</h2>
        <UploadForm/> 
      </Container>
    </div>
  );
};

export default ConvertPage;
