import React, { useState } from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import './style.scss';

const FormatInfo = () => {
  const [selectedFormat, setSelectedFormat] = useState(null);

  const formats = [
    { 
      name: 'JPG', 
      info: 'JPEG (Joint Photographic Experts Group) - a standard for compressing images. It uses lossy compression, which may result in slight quality loss upon repeated saving. Widely used in photography and web graphics.',
      pros: [
        'Lossy Compression: JPEG uses lossy compression, which means it can significantly reduce the file size of images while maintaining decent quality. This makes it ideal for web usage where smaller file sizes are desirable for faster loading times.',
       'Wide Compatibility: JPEG is widely supported across various platforms, devices, and software applications, making it a versatile choice for sharing and displaying images.',
       "Good for Photographs: JPEG is well-suited for photographs and images with complex color gradients, such as digital photographs, due to its ability to preserve details and colors reasonably well despite compression.",
       "Adjustable Compression Levels: Users can adjust the compression level when saving a JPEG image, trading off between file size and image quality according to their needs."
      ],

      cons: ['Loss of Quality: Since JPEG is a lossy compression format, it sacrifices some image quality to achieve smaller file sizes. This loss of quality can be noticeable, especially when using high compression settings or repeatedly editing and saving the image.',
      'Artifacting: JPEG compression can introduce artifacts, such as blockiness or blurring, particularly in areas with fine details or sharp edges. This can degrade the visual quality of the image, especially at lower quality settings.',
      "Limited to 8-bit Color Depth: JPEG supports only 8-bit color depth, which means it may not be the best choice for images requiring higher color accuracy, such as graphics with solid colors or text.",
      "Not Suitable for Lossless Compression: Unlike formats like PNG or GIF, JPEG does not support lossless compression. This means that once an image is saved as a JPEG and compressed, some original image data is permanently lost, making it unsuitable for scenarios where preserving every detail is critical."
     ]
    },
    { 
      name: 'PNG', 
      info: 'PNG (Portable Network Graphics) - a raster graphics file format that supports lossless data compression. Suitable for images with text, line art, and graphics with sharp edges.',
      pros: [
        'Lossless Compression: PNG uses lossless compression, which means it can reduce file size without sacrificing image quality. This makes it suitable for images where preserving every detail is important, such as graphics, logos, and screenshots.', 

        'Transparency Support: PNG supports alpha channels, allowing for transparent backgrounds and smooth blending of images over different backgrounds. This makes it ideal for web graphics and designs where transparency effects are required.',

        "Sharp and Crisp Images: PNG preserves sharpness and detail in images, making it suitable for images with text or fine details, such as diagrams, charts, and text-heavy graphics.",

        "Wide Color Support: PNG supports various color depths, including 8-bit, 24-bit, and 32-bit, allowing for high-quality images with accurate color representation. It also supports indexed color, which can reduce file size for images with limited color palettes.",

        "Lossless Editing: Since PNG is a lossless format, you can edit and save PNG images multiple times without degrading image quality, making it suitable for professional editing and design work."

      ],

      cons: [
        'Larger File Sizes: Compared to lossy formats like JPEG, PNG files tend to have larger file sizes, especially for images with complex details or large dimensions. This can impact loading times on web pages and consume more storage space.',

         'Limited Compression Efficiency: While PNG compression is lossless, it may not achieve as much compression as lossy formats like JPEG, especially for photographic images with smooth gradients or large areas of similar colors.',

         "Not Ideal for Photographs: PNG is not the best choice for photographic images with complex color gradients or continuous tones, as it can result in larger file sizes compared to formats optimized for photographs, such as JPEG.",

         "Limited Browser Compatibility for Certain Features: Although PNG is widely supported by most modern web browsers, certain advanced features like alpha transparency may not be fully supported in older browsers or certain mobile devices, potentially leading to compatibility issues."
        
        ]
    },
    { 
      name: 'GIF', 
      info: 'GIF (Graphics Interchange Format) - a bitmap image format that supports animations and a transparent background. Limited to 256 colors.',
      pros: [
        'Support for Animation: GIF supports animation, allowing multiple frames to be stored in a single file. This makes it suitable for creating simple animations, such as banners, icons, and short looping clips, without the need for complex scripting or plugins.', 
        
        'Transparency Support: GIF supports transparency, allowing one color within the image to be designated as transparent. This enables images to blend seamlessly with different backgrounds, making it useful for creating graphics with irregular shapes or overlays.',

        "Wide Compatibility: GIF is widely supported across various platforms, devices, and web browsers, making it a versatile choice for sharing and displaying images with animation or transparency effects.",

        "Lossless Compression: GIF uses lossless compression, which means it preserves image quality without sacrificing details. This makes it suitable for images with sharp edges, solid colors, and text, where preserving clarity is important."
      ],
      cons: [
        'Limited Color Palette: GIF is limited to a maximum of 256 colors, which can result in reduced color accuracy and visible dithering, especially for images with gradients or continuous tones. This limitation may not be ideal for images requiring high color fidelity.', 
      
      'Large File Sizes for Complex Animations: GIF files can become quite large, especially for complex animations with many frames or high-resolution images. This can impact loading times on web pages and consume more bandwidth, particularly for users with slower internet connections.',
    
      "Limited Support for Video: While GIF can technically support animation, it's not well-suited for longer or more complex animations compared to video formats like MP4 or WebM. GIFs tend to have larger file sizes and lower frame rates, making them less efficient for longer animations.",

      "No Support for Audio: Unlike video formats, GIF does not support audio, so it cannot include synchronized sound or music with animations. This limits its usefulness for multimedia presentations or interactive content where audio is important."
    ]
    },
    { 
      name: 'BMP', 
      info: 'BMP (Bitmap Image File) - a standard Windows bitmap image format. Supports uncompressed raster images with color depths of 1, 4, 8, 16, 24, and 32 bits per pixel.',
      pros: [
        'Simple Structure: BMP is a straightforward file format with a simple structure, making it easy to read, write, and manipulate with minimal processing overhead. This simplicity makes it suitable for applications where efficiency and compatibility are important.', 
        
        'Lossless Compression Option: While BMP files can be uncompressed, they also support lossless compression, known as Run-Length Encoding (RLE). This compression method can reduce file sizes for images with large areas of uniform color without sacrificing image quality.',
      
        "Wide Compatibility: BMP is supported by most operating systems, graphics software, and programming libraries, making it a versatile choice for storing and sharing images across different platforms and applications.",

        "Support for Various Color Depths: BMP supports various color depths, including 1-bit monochrome, 8-bit grayscale, and 24-bit true color, allowing for images with different levels of color accuracy and complexity.",

      ],
      cons: [
        'Large File Sizes: Uncompressed BMP files can be significantly larger than other image formats, especially for high-resolution images or those with true color depth. This can lead to increased storage requirements and longer transfer times, particularly for large collections of images.', 
        
        'Limited Compression Efficiency: While BMP supports lossless compression, its compression efficiency may not be as high as more modern formats like PNG or TIFF. This can result in larger file sizes compared to other formats, especially for images with complex details or smooth gradients.',
      
        "Not Suitable for Web Usage: Due to their large file sizes, BMP files are generally not suitable for web usage, where smaller file sizes are preferred for faster loading times and reduced bandwidth usage. Web developers typically use formats like JPEG, PNG, or GIF for web graphics instead.",

        "Limited Metadata Support: BMP has limited support for metadata, such as EXIF data commonly found in JPEG images. This can make it less suitable for applications where detailed image information or editing history needs to be preserved.",
      ]
    },
    { 
      name: 'SVG', 
      info: 'SVG (Scalable Vector Graphics) - an XML-based vector image format for two-dimensional graphics with support for interactivity and animation.',
      pros: [
        'Scalability: SVG images are resolution-independent and can be scaled to any size without losing quality. This makes them ideal for various applications, including web design, where images need to adapt to different screen sizes and resolutions.', 
        
        'Vector Graphics: SVG is based on vector graphics, which describe shapes using mathematical equations rather than pixels. This allows SVG images to maintain sharpness and clarity at any size, making them suitable for logos, icons, illustrations, and other graphics requiring precise shapes and lines.',
      
        "Small File Sizes: SVG files are typically small in size compared to raster image formats like JPEG or PNG, especially for simple graphics with basic shapes and colors. This can lead to faster loading times on web pages and reduced bandwidth usage.",

        "Editable and Animatable: SVG images are XML-based and can be edited directly with text editors or graphic design software. They also support animations and interactivity using CSS and JavaScript, making them suitable for dynamic and interactive web content.",

        "Accessibility: SVG images can include metadata and semantic markup, making them accessible to screen readers and assistive technologies. This ensures that visually impaired users can access and understand the content of SVG images."

      ],
      cons: [
        'Complexity for Detailed Images: While SVG is great for simple graphics and icons, it may not be the best choice for highly detailed images with complex textures or shading. Vector graphics are better suited for clean, geometric shapes rather than intricate details.', 
        
        'Browser Compatibility: While modern web browsers have good support for SVG, some older browsers may have limited or incomplete support for certain features, such as CSS animations or complex vector effects. This can lead to inconsistent rendering across different browsers and devices.',

        "Performance Considerations: While SVG images are lightweight compared to raster images, complex SVGs with many elements or intricate designs can still impact performance, especially on lower-powered devices or older browsers. Careful optimization may be necessary to ensure smooth rendering and responsiveness.",

        "Limited Photo Realism: SVG is not well-suited for photorealistic images or images with complex gradients and textures, as vector graphics are better at representing clean, geometric shapes rather than organic forms.",

      
      ]
    },
    { 
      name: 'WEBP', 
      info: 'WEBP - a modern image format developed by Google. Supports lossy and lossless compression as well as animation and transparency.',
      pros: [
        'Superior Compression: WebP offers superior compression compared to other common image formats like JPEG and PNG. This results in smaller file sizes for images while maintaining similar or even better quality. Smaller file sizes lead to faster loading times, making it ideal for web usage where speed is crucial.', 

        'Lossy and Lossless Compression: WebP supports both lossy and lossless compression modes. Lossy compression provides excellent compression ratios with minimal loss of quality, making it suitable for web images. Lossless compression preserves image quality perfectly, making it ideal for preserving image fidelity in professional workflows.',

        "Alpha Transparency: WebP supports alpha transparency, allowing images to have transparent backgrounds or partially transparent areas. This makes it suitable for creating images with complex transparency effects, such as logos, icons, and overlays.",

        "Animation Support: Similar to GIF, WebP also supports animation. However, WebP animations typically have smaller file sizes compared to GIF animations, leading to faster loading times and smoother playback.",

        "Wide Browser Support: While initially developed by Google, WebP has gained increasing support across various web browsers and platforms. Most modern browsers now support WebP images, allowing for wider adoption and compatibility.",

      ],
      cons: [
        'Limited Support in Some Software: While WebP is widely supported by web browsers, it may not be as widely supported by image editing software and other applications compared to more established formats like JPEG and PNG. This could pose challenges when working with WebP images in certain workflows.', 
      'Potential Loss of Metadata: Some implementations of WebP compression may not preserve metadata such as EXIF data found in JPEG images. This could be a concern for photographers or users who rely on metadata for organization and information.',
      "Less Familiarity: Compared to JPEG and PNG, WebP may be less familiar to some users and developers. This could lead to hesitation or reluctance to adopt WebP as the primary image format, especially in existing workflows or projects.",

    ]
    },
    { 
      name: 'EPS', 
      info: 'EPS (Encapsulated PostScript) - a graphics file format that contains both vector and bitmap graphics and supports transparency and embedding of fonts.',
      pros: [
        'Vector Graphics: EPS is primarily a vector graphics format, which means it describes images using mathematical equations rather than pixels. This allows EPS images to be scaled to any size without losing quality, making them suitable for high-resolution printing and professional graphic design work.', 
      'PostScript Language: EPS files are based on the PostScript language, a powerful page description language developed by Adobe. This allows EPS files to contain complex graphics, fonts, and other elements, making them versatile for various publishing and printing applications.',
      "Compatibility: EPS files are widely supported by graphic design software, desktop publishing applications, and printing devices. This makes EPS a common interchange format for sharing graphics and illustrations between different programs and platforms.",
      "Embeddable Fonts: EPS files can embed fonts used in the document, ensuring accurate reproduction of text regardless of the availability of fonts on the viewer's system. This is important for maintaining consistency and fidelity in printed materials.",

    ],
      cons: [
        'Large File Sizes: EPS files can become quite large, especially for complex graphics with many vector elements or embedded fonts. This can impact storage requirements and file transfer times, particularly for large collections of EPS files.', 
      'Limited Color Support: While EPS supports both RGB and CMYK color modes, it may not be the best choice for images requiring extensive color management or advanced color effects. Other formats like TIFF or PSD may offer better color support for such scenarios.',
      "Not Suitable for Web Usage: Due to their large file sizes and complex structure, EPS files are not typically used for web graphics or online publishing. Web developers typically use raster formats like JPEG, PNG, or SVG for web graphics instead.",
      "Compatibility Issues with Some Software: While EPS is widely supported, there may be compatibility issues when importing or exporting EPS files between different software applications. This can result in unexpected changes to the appearance or layout of the graphics.",]
    },
    { 
      name: 'PSD', 
      info: 'PSD (Photoshop Document) - a proprietary file format developed by Adobe for storing layered images and other Photoshop-specific data.',
      pros: [
        'Layers: PSD files support layers, allowing users to work with multiple elements independently. Layers make it easy to edit specific parts of an image without affecting the rest, providing greater flexibility and control over the editing process.', 
      'Editable Text: Text layers in PSD files retain their editability, allowing users to modify text content even after the file has been saved. This makes PSD files suitable for creating templates, designs, and mockups that require customizable text elements.',
      "Support for Various Color Modes: PSD files support various color modes, including RGB, CMYK, and grayscale, allowing users to work with different color spaces depending on the intended output. This flexibility makes PSD files suitable for both web and print design.",
      "Smart Objects: PSD files can contain smart objects, which are linked to external files and retain their original properties even when transformed or edited. Smart objects make it easy to reuse and update elements across multiple documents without losing quality.",
      "Preservation of Image Quality: PSD files use lossless compression, preserving the quality of the original image data. This ensures that edits made to the image remain crisp and sharp without degradation over multiple saves.",

    ],
      cons: [
        'Large File Sizes: PSD files can become large, especially for documents with many layers, high-resolution images, or embedded smart objects. This can consume significant storage space and impact file transfer times, particularly for large projects.', 
      'Limited Compatibility: While PSD is the native file format for Adobe Photoshop, it may not be fully compatible with other image editing software. Some features, such as layer styles and adjustment layers, may not translate perfectly when opening PSD files in non-Adobe software.',
      "Not Suitable for Web Usage: Due to their large file sizes and proprietary nature, PSD files are not typically used for web graphics or online publishing. Web developers typically use raster formats like JPEG, PNG, or SVG for web graphics instead.",
      "Complexity: PSD files can be complex, especially for projects with many layers, effects, and adjustments. This complexity may be overwhelming for users unfamiliar with Photoshop or for collaboration between multiple designers.",
      'Version Compatibility: Newer versions of Adobe Photoshop may introduce features or enhancements that are not backward compatible with older versions. This can cause compatibility issues when sharing PSD files between users with different software versions.']
    },
    { 
      name: 'PDF', 
      info: 'PDF (Portable Document Format) - a file format used to present and exchange documents reliably, independent of software, hardware, or operating system.',
      pros: [
        'Universal Compatibility: PDF files can be viewed and accessed on virtually any device or operating system using free software such as Adobe Acrobat Reader. This makes PDF a universal format for sharing documents across different platforms without worrying about compatibility issues.', 
      'Preservation of Formatting: PDF files preserve the formatting and layout of the original document, ensuring that the content appears the same regardless of the viewing device or software used. This makes PDF suitable for documents requiring precise layout and design, such as contracts, reports, and presentations.',
      "Security Features: PDF files support various security features, including password protection, encryption, and digital signatures, to safeguard sensitive information and prevent unauthorized access or modification. This makes PDF suitable for confidential documents and legal agreements.",
      "Interactive Elements: PDF files can contain interactive elements such as hyperlinks, bookmarks, forms, and multimedia content, enhancing the user experience and allowing for interactivity within the document. This makes PDF suitable for creating interactive reports, manuals, and educational materials.",
      'Compression: PDF files support efficient compression methods to reduce file size without sacrificing quality. This can lead to smaller file sizes, making it easier to share and distribute documents over the internet or via email.'

    ],
      cons: [
        'Editing Limitations: While PDF files can be annotated and edited to some extent using specialized software, such as Adobe Acrobat, making extensive edits to the content or layout of a PDF document can be challenging. This can be a drawback for collaborative editing or updating of documents.', 
      'Complexity: PDF files can be complex, especially for documents with advanced formatting, graphics, or multimedia elements. Creating or editing complex PDF files may require specialized software and technical expertise, which can be intimidating for some users.',
      "Accessibility: While PDF files can be made accessible to users with disabilities through features like text-to-speech and screen reader compatibility, poorly optimized PDFs may present accessibility challenges. This can hinder access to information for users with visual or cognitive impairments.",
      "Version Compatibility: Newer versions of the PDF format may introduce features or enhancements that are not supported by older software or viewers. This can cause compatibility issues when sharing or viewing PDF files across different platforms or software versions.",
    "Potential for Malicious Content: PDF files can contain embedded scripts or links that may pose security risks if not properly managed. Users should exercise caution when opening PDF files from unknown or untrusted sources to avoid potential malware or phishing attacks."
    ]
    },
    { 
      name: 'DOCX', 
      info: 'DOCX (Microsoft Word Open XML Document) - a file format used by Microsoft Word for documents containing text, images, tables, and other elements.',
      pros: [
        'Universal Compatibility: DOCX files can be opened and edited using Microsoft Word, which is widely used across various platforms and operating systems. Additionally, many other word processing software applications, such as Google Docs, LibreOffice, and Apache OpenOffice, support the DOCX format, ensuring broad compatibility.', 
      'Rich Formatting Options: DOCX files support a wide range of formatting options, including fonts, styles, colors, tables, images, and more. This allows users to create professional-looking documents with precise layout and design, suitable for various purposes such as reports, letters, resumes, and presentations.',
      "Collaboration Features: Microsoft Word and other word processing software applications offer collaboration features that allow multiple users to work on the same document simultaneously. This includes features such as track changes, comments, and real-time editing, facilitating collaboration and teamwork.",
      "Integration with Other Software: DOCX files can easily be integrated with other Microsoft Office applications, such as Excel and PowerPoint, allowing users to insert and edit content seamlessly across different documents and formats. This integration enhances productivity and workflow efficiency.",
      "Accessibility Features: Microsoft Word and other word processing software applications offer accessibility features to ensure that documents are accessible to users with disabilities. This includes features such as screen reader compatibility, alternative text for images, and accessibility checkers to identify and address accessibility issues."

    ],
      cons: [
        'Proprietary Format: DOCX is a proprietary file format owned by Microsoft, which means that its specifications are not publicly available. This can lead to compatibility issues with non-Microsoft software and difficulties in accessing or converting DOCX files without using Microsoft products.', 
      'Potential for Formatting Issues: When opening DOCX files in different word processing software applications or versions, there is a risk of formatting discrepancies or loss of formatting. This can occur due to differences in rendering engines or compatibility issues between software applications.',
      "File Size: DOCX files can become large, especially for documents with extensive formatting, images, or embedded objects. This can impact storage requirements and file transfer times, particularly for large documents or when sharing files via email or over the internet.",
      "Security Risks: DOCX files can potentially contain macros or embedded content that may pose security risks if not properly managed. Users should exercise caution when opening DOCX files from unknown or untrusted sources to avoid potential malware or phishing attacks.",
    "Limited Compatibility with Older Versions: While newer versions of Microsoft Word can open and edit DOCX files, older versions may have limited compatibility with certain features or formatting options. This can cause issues when sharing or collaborating on documents with users who are using older software versions."]
    },
    { 
      name: 'XLSX', 
      info: 'XLSX (Microsoft Excel Open XML Spreadsheet) - a file format used by Microsoft Excel for spreadsheets containing data, formulas, charts, and other elements.',
      pros: [
        'Universal Compatibility: XLSX files can be opened and edited using Microsoft Excel, which is widely used across various platforms and operating systems. Additionally, many other spreadsheet software applications, such as Google Sheets, LibreOffice Calc, and Apache OpenOffice Calc, support the XLSX format, ensuring broad compatibility.', 
      'Data Organization: XLSX files are well-suited for organizing and analyzing data in tabular format. They support features such as formulas, functions, sorting, filtering, and conditional formatting, making it easy to perform complex calculations and analysis on large datasets.',
      "Rich Formatting Options: XLSX files support a wide range of formatting options for cells, including fonts, colors, borders, and alignment. This allows users to create visually appealing and easy-to-read spreadsheets suitable for various purposes such as financial statements, budgets, inventory lists, and project plans.",
      "Integration with Other Software: XLSX files can easily be integrated with other Microsoft Office applications, such as Word and PowerPoint, allowing users to insert and link data from spreadsheets into other documents and presentations. This integration enhances productivity and workflow efficiency.",
      "Collaboration Features: Microsoft Excel and other spreadsheet software applications offer collaboration features that allow multiple users to work on the same workbook simultaneously. This includes features such as shared workbooks, comments, and real-time editing, facilitating collaboration and teamwork."

    ],
      cons: [
        'Proprietary Format: XLSX is a proprietary file format owned by Microsoft, which means that its specifications are not publicly available. This can lead to compatibility issues with non-Microsoft software and difficulties in accessing or converting XLSX files without using Microsoft products.', 
      'Large File Sizes: XLSX files can become large, especially for spreadsheets with extensive data or formatting. This can impact storage requirements and file transfer times, particularly for large workbooks or when sharing files via email or over the internet.',
      "Compatibility with Older Versions: While newer versions of Microsoft Excel can open and edit XLSX files, older versions may have limited compatibility with certain features or formatting options. This can cause issues when sharing or collaborating on spreadsheets with users who are using older software versions.",
      "Potential for Formula Errors: XLSX files can contain complex formulas and functions, which may lead to errors if not properly managed or updated. Users should be cautious when working with formulas and ensure that calculations are accurate and up-to-date.",
    "Limited Visualization Options: While Excel offers various charting and graphing tools, the visualization options may be limited compared to dedicated data visualization software. Users may need to export data from Excel to other tools for more advanced visualization and analysis."]
    },
    { 
      name: 'CSV', 
      info: 'CSV (Comma-Separated Values) - a plain text file format used to store tabular data, such as a spreadsheet or database.',
      pros: [
        'Simplicity: CSV is a simple and lightweight file format that stores tabular data in plain text, with each row representing a record and each column separated by a delimiter, typically a comma or semicolon. This simplicity makes CSV easy to create, read, and edit using any text editor or spreadsheet software.', 
      'Universal Compatibility: CSV files can be opened and edited using virtually any spreadsheet software, including Microsoft Excel, Google Sheets, LibreOffice Calc, and others. This ensures broad compatibility across different platforms and operating systems.',
      "Compact File Size: CSV files have a compact file size compared to proprietary spreadsheet formats like XLSX or ODS. This makes CSV an efficient choice for storing and transferring large datasets, especially over networks with limited bandwidth.",
      "Flexibility: CSV files can store various types of data, including text, numbers, dates, and special characters. This flexibility makes CSV suitable for a wide range of applications, such as data import/export, data exchange between different systems, and data storage for analysis and reporting.",
      "Integration: CSV files can easily be integrated with other software systems and programming languages using standard file handling techniques. Many programming languages, such as Python, R, and JavaScript, provide built-in support for reading and writing CSV files, facilitating data processing and automation tasks."

    ],
      cons: [
        'Limited Metadata: CSV files do not support metadata or formatting information, such as column headers, data types, or cell formatting. This can make it challenging to preserve data integrity and structure, especially when importing or exporting data between different systems.', 
      'No Standardization: There is no official standard for CSV files, leading to variations in how different software applications handle CSV files. This can result in compatibility issues or data loss when transferring CSV files between different software platforms.',
      "Handling of Special Characters: CSV files may encounter issues when dealing with special characters, such as commas, quotation marks, or line breaks, within the data. These characters can disrupt the delimiter-based parsing of the data and require special handling or encoding to avoid errors.",
      "Limited Support for Complex Data Structures: While CSV is suitable for tabular data, it may not be the best choice for storing complex data structures, such as hierarchical or nested data. In such cases, other formats like JSON or XML may be more appropriate.",
    "No Support for Data Validation: CSV files do not support data validation or constraints, such as enforcing data types, unique values, or referential integrity. This can lead to data integrity issues if the data is not carefully managed or validated before being imported into a database or analyzed."]
    }
  ];

  const toggleInfo = (formatName) => {
    if (selectedFormat === formatName) {
      setSelectedFormat(null);
    } else {
      setSelectedFormat(formatName);
    }
  };

  return (
    <Accordion>
      {formats.map((format, index) => (
        <Card key={index}>
          <Card.Header>
            <Accordion  eventKey={index.toString()}>
              <Accordion.Header >
                {format.name}
              </Accordion.Header>
              <Accordion.Body>
              <Card.Body>
              <p>{format.info}</p>
              <p>Pros:</p>
              <ul>
                {format.pros.map((pro, index) => (
                  <li key={index}>{pro}</li>
                ))}
              </ul>
              <p>Cons:</p>
              <ul>
                {format.cons.map((con, index) => (
                  <li key={index}>{con}</li>
                ))}
              </ul>
            </Card.Body>
              </Accordion.Body>
            </Accordion>
          </Card.Header>
        </Card>
      ))}
    </Accordion>
  );
};

export default FormatInfo;
