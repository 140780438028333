// App.js
import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route, Navigate } from "react-router-dom";

import HistoryPage from "./pages/history";
import Header from "./components/header";
import Footer from "./components/footer";
import About from "./pages/about";
import ConvertPage from "./pages/convert";
// import AdSense from './components/addSense';
import RegisterForm from "./components/forms/sign-up";
import LoginForm from "./components/forms/login";
import { Toast } from "./components/toast";
import { useToast } from "./context/toastContext";
import { useAuth } from "./context/userContext";
import UserProfile from "./pages/profile";

function App() {
  const {
    showToast,
    setShowToast,
    toastStatus,
    toastMessage,
    handleCloseToast,
  } = useToast();

  const { isLoggedIn, setIsLoggedIn } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Header />
      <div style={{ flex: 1 }}>
        <Routes>
          <Route path="/*" element={<ConvertPage />} />
          <Route path="/converter" element={<ConvertPage />} />
          <Route
            path="/history"
            element={isLoggedIn ? <HistoryPage /> : <LoginForm />}
          />
          <Route
            path="/profile"
            element={isLoggedIn ? <UserProfile /> : <LoginForm />}
          />
          <Route path="/formats" element={<About />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/register" element={<RegisterForm />} />
        </Routes>
      </div>
      <Footer />
      {showToast && (
        <Toast
          status={toastStatus}
          message={toastMessage}
          onClose={handleCloseToast}
          showToast={showToast}
          setShowToast={setShowToast}
        />
      )}
    </div>
  );
}

export default App;
