import React from "react";
import { useForm } from "react-hook-form";
import { Form, Button, Card } from "react-bootstrap";
import useAuthService from "../../../service/authService";

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const {login, forgotPassword} = useAuthService()
  

  return (
    <div style={{ minHeight: "100vh", paddingTop:"100px"}}>
    <h2 style={{
      textAlign:'center'
    }}>Login</h2>
      <Card
        style={{
          maxWidth: "400px",
          margin: "auto",
          marginTop: "50px",
          padding: "20px",
          border: "1px solid #ccc",
        }}
      >
        <Form onSubmit={handleSubmit(login)}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              {...register("email", { required: true })}
            />
            {errors.email && <p className="text-danger">Email is required</p>}
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              {...register("password", { required: true })}
            />
            {errors.password && (
              <p className="text-danger">Password is required</p>
            )}
          </Form.Group>
          <p onClick={()=>forgotPassword(getValues("email"))} style={{
            cursor:"pointer"
          }} className="text-danger">Forgot password?</p>
          <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <Button variant="primary" type="submit">
            Login
          </Button>
        </div>
        </Form>
      </Card>
    </div>
  );
};

export default LoginForm;
